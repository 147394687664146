.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
 



  
  .nav-link {
    color: black;
    cursor: pointer;
    font-size: 14px;
  }
  .active.nav-link{
    color: #23528b;
  }
  // .sidenav-bg:hover {
  //   background-color: rgba(255, 255, 255, 0.1);
  //   border-radius: $border-radius;
  //   cursor: pointer;
  //   .nav-link {
  //     color: green;
  //   }
    
  // }
  // .sidenav-bg .active {
 
  //   border-radius: $border-radius;
  //   color: green;
  // }
}



@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 0;
    z-index: 1;
    margin-left: -$sidebarWidth;
overflow-y: scroll;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
